<template>
  <b-container class="mt-4">
    <!-- <h1 class="mb-5">Hints</h1> -->

    <b-row>
      <b-col md="4" cols="12"></b-col>
      <b-col md="4" cols="12">
        <div class="text-center mb-5">
          <h1 class="font-brand brand-color" style="font-size:4rem;">Hints</h1>
          <h4 class="font-2 brand-color">coming soon</h4>
        </div>
        <b-card class="mt-5 text-center">
          <div class="regular font-2">solving the </div>
          <div class="large font-2 mb-5">cold email dilemma</div>
          <!-- <div class="regular font-2 mb-5">for that problem you have?...</div> -->

          <div class="text-center w-100">
          <div class="small font-2">Send us a message to learn more</div>
            <contact-form-anonymous />

            <!-- <SmileySvg
              style="color:yellow;height:auto;"
              color="rgb(109, 183, 237)"
              size="5rem"
              class=""
            /> -->
          </div>
        </b-card>
      </b-col>
      <b-col md="4" cols="12"></b-col>
    </b-row>
  </b-container>
</template>

<script>
import ContactFormAnonymous from '../components/ContactFormAnonymous.vue'
// import axios from 'axios';
// import EarningsSection from '../../components/EarningsSection.vue'
// import SmileySvg from '../../assets/icons/SmileySvg.vue'

export default {
  name: 'MarketingPage',
  components: {
    // SmileySvg
    ContactFormAnonymous
  },
  data() {
    return {

    }
  },
  computed: {
    // currentUser() {
    //   return this.$store.state.currentUser
    // },
  },
	mounted() {

  },
  created() {

  },
  watch: {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>

span {
  font-weight: 400;
  font-family: Larsseit-Thin;
  // thin is thinner than light
  // font-family: Larsseit-Light;
}

.card {
  border-radius: 0.75rem;
}


div, span {
  font-family: Larsseit-Light;
}

.regular {
  font-size: 1.25rem;
}
.large {
  font-size: 2rem;
}

.small {
  font-size: 1rem;
}

</style>
