<template>
  <b-container class="mt-4" @click="openModal()" style="cursor:pointer;">

    <b-row>
      <b-col cols="2"></b-col>
      <b-col md="8">
        <h3>send message demo</h3>
        <div class="video-wrapper">
          <video
            ref="firstVideo"
            class="demo-video"
            muted
            playsinline
            @timeupdate="checkFirstVideoProgress"
            @ended="playSecondVideo"
          >
            <source :src="firstVideoUrl" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h3>receive payment demo</h3>
        <div class="video-wrapper">
            <video
              ref="secondVideo"
              class="demo-video"
              muted
              playsinline
            >
              <source :src="secondVideoUrl" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </b-container>
</template>

<script>
import { createAnalyticsEvent } from '../hooks/helperFunctions'
export default {
  name: 'DemoPage',
  data() {
    return {
      firstVideoUrl: 'https://storage.googleapis.com/hints-public-bucket-1/hints-public-images/hints-p1-double.mov',
      secondVideoUrl: 'https://storage.googleapis.com/hints-public-bucket-1/hints-public-images/hints-p2-double.mov',
      transitionThreshold: 0.9, // Start second video when first is 80% complete
    }
  },
  mounted() {
    // Start playing the first video when the page loads
    this.$nextTick(() => {
      const firstVideo = this.$refs.firstVideo;
      if (firstVideo) {
        firstVideo.play().catch(error => {
          console.error('Error autoplaying first video:', error);
        });
      }
    });

    createAnalyticsEvent('visited-demo-page', 'interest', 0);
  },
  methods: {
    checkFirstVideoProgress() {
      const firstVideo = this.$refs.firstVideo;
      if (firstVideo) {
        const progress = firstVideo.currentTime / firstVideo.duration;
        if (progress >= this.transitionThreshold) {
          this.playSecondVideo();
        }
      }
    },
    playSecondVideo() {
      const secondVideo = this.$refs.secondVideo;
      if (secondVideo && secondVideo.paused) {
        secondVideo.play().catch(error => {
          console.error('Error playing second video:', error);
        });
      }
    },
    openModal() {
      this.$bvModal.show('message-form-modal');
    }
  }
}
</script>

<style lang="scss" scoped>
.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; // 16:9 aspect ratio
  height: 0;
  overflow: hidden;
  border-radius: 0.75rem;
  margin-bottom: 1rem;
}

.demo-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #000;
}

// Preserve existing styles
.card {
  border-radius: 0.75rem;
}

div, span {
  font-family: Larsseit-Light;
}

span {
  font-weight: 400;
  font-family: Larsseit-Thin;
}

.large {
  font-size: 1.5rem;
}
</style>
