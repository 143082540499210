<template>
  <div>
    <b-navbar v-if="version2" toggleable="lg" type="dark" variant="info">
      <b-navbar-brand href="/#/">Hints</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item :href="'/#/following'">Following</b-nav-item>
          <b-nav-item v-if="currentUser.user" :href="'/#/users/' + currentUser.user.uuid + '/connections'">Connections</b-nav-item>
          <b-nav-item href="/#/search"><b-icon icon="search" /></b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!-- <b-nav-item>{{ parent }}</b-nav-item> -->
          <!-- <b-nav-item v-if="testEnvironment()" style="background:lightsalmon;">STAGING</b-nav-item> -->
          <b-nav-item href="/#/onboard">onboard</b-nav-item>
          <b-nav-item :href="'/#/inbox/'">
            <!-- <template #button-content> -->
              Inbox
              <b-badge
              v-if="unreadCount"
              variant="danger"
              pill
              >
              {{ unreadCount }}
            </b-badge>
          <!-- </template> -->
        </b-nav-item>
        <!-- <b-nav-item href="/#/profile">Profile</b-nav-item> -->
        <!-- <b-nav-item v-if="currentUser.user" :href="'/#/users/' + currentUser.user.uuid">Profile</b-nav-item> -->
        <b-nav-item-dropdown v-if="currentUser.user" :text="currentUser.user.full_name || currentUser.user.email" right>
          <b-dropdown-item v-if="currentUser.user" :href="currentUser.public_share_link">Profile</b-dropdown-item>
            <b-dropdown-item href="/#/notifications">Requests</b-dropdown-item>
            <b-dropdown-item href="/#/payments">Payments sent</b-dropdown-item>
            <b-dropdown-item href="/#/receipts">Payments received</b-dropdown-item>
            <b-dropdown-item href="/#/settings">Settings</b-dropdown-item>
            <b-dropdown-item v-if="currentUser.authenticated" href="/api-auth/logout/?next=/accounts/login">Log out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
    <!-- <profile-wizard @finish-wizard="finishWizard()" :show="showWizard" /> -->

  </div>
</template>


<script>
// import axios from 'axios'
// import VueCookies from 'vue-cookies'
// import ProfileWizard from './simplifi/ProfileWizard.vue'

export default {
  name: 'NavBar',
  data() {
    return {
      version2: true,
      username: '',
      authenticated: false,
      notifications: [],
      // showWizard: false,
    }
  },
  components: {
    // ProfileWizard,
  },
  props: [
    'parent'
  ],
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    unreadCount() {
      return this.currentUser?.unread_threads_count || 0
    }
  },
  created() {

  },
  methods: {
    finishWizard () {
      // FYI theres a bit of a chained-event sequence with these emits
      // ProfileWizard -> Navbar -> AppDashboard
      this.showWizard = false
      this.$emit('fetch-dashboard-data')
    },

    testEnvironment() {
      return process.env.VUE_APP_ENV != 'PROD'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
