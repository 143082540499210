
import axios from 'axios';

export function createAnalyticsEvent(eventAction, category, uuid) {
  // console.log('creating analytics event?!?')
  // console.log(eventAction)
  // console.log(category)
  // console.log(uuid)
  const payload = {
    event_action: eventAction,
    category: category,
    uuid: uuid,
  }
  // axios.post(`${process.env.VUE_APP_HOST}/api/create_analytics_event/`, payload, {headers: { "content-type": "application/json", 'X-CSRFToken': this.csrfToken}} )
  axios.post(`${process.env.VUE_APP_HOST}/api/create_analytics_event/`, payload, {headers: { "content-type": "application/json"}} )
  .then(response => {
    console.log(response)
      // this.currentUser = response.data
  })
  .catch(e => {
      this.errors.push(e)
  })

}

export function formatPrice(cents) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(cents / 100);
}

// export function differentEvent() {
//   console.log('different Event?')
// }
