import Vue from 'vue'
import Vuex from 'vuex'

import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

// import * as modules from './lib'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { LayoutPlugin, SidebarPlugin } from 'bootstrap-vue'
import VueCookies from 'vue-cookies'
import VueRouter from 'vue-router'
import './assets/custom.scss' // important! and should be above BootstrapVue


// ~~~~~~~~~~~~~~~~~~
// HINTS
// ~~~~~~~~~~~~~~~~~~
import AppMarketingSite from './AppMarketingSite.vue'
import TemporaryHomepage from './hints/pages/TemporaryHomepage'
import MarketingHomePage from './hints/pages/MarketingHomePage'
import DemoPage from './hints/pages/DemoPage'

import OrderSuccessPage from './hints/pages/OrderSuccessPage'
// import UserPage from './hints/pages/UserPage'
import UserCollectionPage from './hints/pages/UserCollectionPage'
import CollectionPage from './hints/pages/CollectionPage'
import ContactPage from './hints/staticCopy/ContactPage.vue'
import PrivacyPage from './hints/staticCopy/PrivacyPage.vue'
import TermsPage from './hints/staticCopy/TermsPage.vue'
import PathNotFound from './hints/PathNotFound'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(LayoutPlugin)
Vue.use(SidebarPlugin)
Vue.use(VueRouter)
Vue.use(VueCookies)
Vue.use(Vuex)

if (process.env.VUE_APP_ENV == "DEV") {
  Vue.config.productionTip = true
}

const routes = [
  { path: '/', component: TemporaryHomepage },
  { path: '/demo', component: DemoPage },
  { path: '/soon', component: MarketingHomePage },
  // { path: '/users/:uuid', component: UserPage },
  { path: '/users/:uuid/collections/:cuid', component: UserCollectionPage },
  { path: '/order/:uuid/complete', component: OrderSuccessPage },
  { path: '/collections/:uuid', component: CollectionPage },

  { path: '/contact', component: ContactPage },
  { path: '/privacy', component: PrivacyPage },
  { path: '/terms', component: TermsPage },
  // { path: '/terms', component: PrivacyPage },
  // { path: '/support', component: SupportPage },
  { path: '/:pathMatch(.*)*', component: PathNotFound },
]

const store = new Vuex.Store({
  state: {
    currentUser: {},
  },
  mutations: {
    setCurrentUser (state, user) {
      state.currentUser = user
    },
  }
})

// new
const router = createRouter({
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
})

createApp(AppMarketingSite).use(store).use(router).mount('#app-marketing-site')
